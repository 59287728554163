import styled from 'styled-components'

const TitleMedium = styled.h2`
	font-family: ${props => props.theme.fonts.medium};
	margin: 0;
	padding-bottom: 20px;
	max-width: 500px;
	font-weight: 400;
`

export default TitleMedium
