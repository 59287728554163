import React from 'react'

import {
	BlockIconsSection,
	BlockIcon,
	Title,
	Content,
	BlockIconLeft,
	BlockIconLeftContentWrapper,
	TitleLeft
} from './BlockIcons.styled'

import Icon from '../../Icon/Icon'

const BlockIcons = data => {
	const content = data.generated ? data.data : data

	return (
		<BlockIconsSection
			paddingTop={content.paddingTop}
			paddingBottom={content.paddingBottom}
			backgroundColor={content.backgroundColor}
			left={content.iconLeft}
		>
			{content.blocks.map((block, i) => {
				return content.iconLeft ? (
					<BlockIconLeft key={i}>
						<Icon color="primary" width="50px" height="50px" icon={block.blockIconsIcon} />
						<BlockIconLeftContentWrapper>
							<TitleLeft>{block.blockIconsTitle}</TitleLeft>
							<Content>{block.blockIconsContent}</Content>
						</BlockIconLeftContentWrapper>
					</BlockIconLeft>
				) : (
					<BlockIcon key={i}>
						<Icon color="primary" width="100px" height="100px" icon={block.blockIconsIcon} />
						<Title>{block.blockIconsTitle}</Title>
						<Content>{block.blockIconsContent}</Content>
					</BlockIcon>
				)
			})}
		</BlockIconsSection>
	)
}

export default BlockIcons
