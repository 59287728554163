import React from 'react'
import parse from 'html-react-parser'
import { getImage } from 'gatsby-plugin-image'

import LinkOrButton from '../../LinkOrButton'
import BackgroundShape from '../../BackgroundShape'
import TitleMedium from '../../TitleMedium'
import HTMLContent from '../../HTMLContent'

import { ImageWrapper, TextImageSection, Image, ContentWrapper, HeadTitle, Subtitle } from './BlockTextImage.styled'

const BlockTextImage = data => {
	const content = data.generated ? data.data : data

	const image = getImage(content.image?.localFile)

	return (
		<TextImageSection
			paddingBottom={content.paddingBottom}
			paddingTop={content.paddingTop}
			position={content.imagePosition}
		>
			<ImageWrapper>
				<Image image={image} alt={content.image?.altText} />
			</ImageWrapper>
			<ContentWrapper>
				{content.h1Tag
					? content.subtitle && <HeadTitle>{parse(content.subtitle)}</HeadTitle>
					: content.subtitle && <Subtitle>{parse(content.subtitle)}</Subtitle>}
				{content.title && <TitleMedium dangerouslySetInnerHTML={{ __html: content.title }} />}
				<HTMLContent
					dangerouslySetInnerHTML={{
						__html: content.text
					}}
				/>
				{content.active && (
					<LinkOrButton type={content.linkOrButton} url={content.link?.url} text={content.linkButtonText} />
				)}
			</ContentWrapper>
			{content.backgroundShape && <BackgroundShape />}
		</TextImageSection>
	)
}

export default BlockTextImage
