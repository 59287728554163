import React from 'react'
import styled from 'styled-components'
// eslint-disable-next-line no-unused-vars
import { graphql } from 'gatsby'
import PageLayout from '../../layouts/PageLayout/PageLayout'
import BlockCallToAction from '../../components/ACF/BlockCallToAction/BlockCallToAction'
import BlockTextImage from '../../components/ACF/BlockTextImage/BlockTextImage'
import BlockIcons from '../../components/ACF/BlockIcons/BlockIcons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import Icon from '../../components/Icon/Icon'

const PopAndBangPageTemplate = ({ data }) => {
	const brandName = data.brand.name

	const popAndBangData = {
		seo: {
			canonical: `/pops-and-bangs/${data.brand.slug}`,
			cornerstone: false,
			focuskw: `pops&bangs ${brandName}`,
			metaDesc: `Wilt u pops & bangs / crackle map voor uw ${brandName}? `,
			metaKeywords: `pops & bangs / crackle map ${brandName} - ECU adaptions`,
			metaRobotsNofollow: 'follow',
			metaRobotsNoindex: 'index',
			opengraphTitle: `pops & bangs / crackle map ${brandName} - ECU adaptions`,
			title: `pops & bangs / crackle map ${brandName} - ECU adaptions`
		},
		header: {
			backgroundColor: '#000000',
			backgroundOpacity: 0.2,
			fieldGroupName: 'header',
			height: 50,
			heroChoice: 'image',
			heroText: data?.brand?.name,
			isFrontPage: false,
			image: data?.brand?.image,
			pxOrVh: 'vh',
			videoUrl: null
		},
		...data?.brand
	}

	const ctaData = { callToAction: data.wpCtaPost }

	return (
		<PageLayout pageData={popAndBangData}>
			<BrandPageContentWrapper padding={'80px 0 0 0'}>
				<Title>{popAndBangData.name}</Title>
				<ContentContainer>
					<TextContainer>
						{' '}
						<TextTitle>Pops & bangs voor uw {popAndBangData.name}</TextTitle>
						<Text>
							{`ECU Adaptions biedt de mogelijkheid om Pops & Bangs-uitlaatgeluiden volledig op maat te programmeren voor uw specifieke ${popAndBangData.name} voertuig. Met onze geavanceerde tuningtechnologie kunnen we de Pops & Bangs-modus naar uw wensen afstemmen, waardoor u een unieke en opwindende rijervaring krijgt. `}
						</Text>
						{`Bovendien is het soms mogelijk om de Pops & Bangs-functie te integreren voor uw ${popAndBangData.name} in de bediening van uw voertuig, vergelijkbaar met andere autofuncties zoals de airconditioning of sportmodus. Hierdoor kunt u de krachtige uitlaatgeluiden met het grootste gemak activeren of uitschakelen, zodat u volledige controle hebt over uw rijervaring.`}
					</TextContainer>
					<FeaturesContainer>
						<TextTitle>{`Mogelijke voordelen van het installeren van Pops & bangs voor uw ${popAndBangData.name}`}</TextTitle>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Betere prestaties
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Verbeterd geluid
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Antilag bij turbocharged voertuigen
						</FeatureItem>
						<FeatureItem>
							<Icon style={{}} width="1.3rem" height="1.3rem" color="#0685C5" margin="0 10px 0 0" icon={faCheck} />
							Geen turbo vertragingen tijdens het optrekken
						</FeatureItem>
					</FeaturesContainer>
				</ContentContainer>
				<BlockCallToAction data={ctaData} />
			</BrandPageContentWrapper>
		</PageLayout>
	)
}

const BrandPageContentWrapper = styled.section`
	padding: ${props => props.padding};
`

const ContentContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;

	@media (min-width: ${props => props.theme.sizes.maxDesktop}) {
		flex-direction: row;
		align-items: center;
	}
`

const TextContainer = styled.div`
	flex: 3;
	margin-right: 3rem;
	margin-bottom: 3rem;
`

const FeaturesContainer = styled.div`
	flex: 2;
	margin-bottom: 3rem;
`

const Title = styled.h1`
	margin: 0;
	margin-bottom: 3rem;
`

const TextTitle = styled.h5`
	margin: 0;
	margin-bottom: 1rem;
	max-width: 600px;
`

const Text = styled.p`
	margin: 0;
	margin-bottom: 1.5rem;
`

const Notice = styled.p`
	margin: 0;
	font-style: italic;
	color: rgb(129, 129, 129);
`

const FeatureItem = styled.p`
	display: flex;
	flex-direction: row;
	margin: 0;
	margin-top: 0.5rem;
	margin-bottom: 0.5rem;
	align-items: center;
`

export default PopAndBangPageTemplate

export const query = graphql`
	query PopAndBangBrandQuery($id: String!) {
		brand(id: { eq: $id }) {
			id
			name
			slug
			image {
				localFile {
					childImageSharp {
						gatsbyImageData(width: 1920)
					}
				}
			}
		}
		wpCtaPost(slug: { eq: "offerte-aanvragen" }) {
			id
			title
			postContent {
				buttonText
				imageOverlay
				imageOverlayOpacity
				paddingBottom
				paddingTop
				text
				buttonLink {
					url
				}
				image {
					altText
					sourceUrl
					localFile {
						childImageSharp {
							gatsbyImageData(width: 1920)
						}
					}
				}
			}
		}
	}
`
