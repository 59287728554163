import styled from 'styled-components'

import Section from '../../Section'

export const BlockIconsSection = styled(Section)`
	display: flex;
	flex-direction: column;
	position: relative;
	gap: 20px;
	justify-content: ${props => (props.left ? 'left' : 'center')};
	width: 100%;
	align-items: ${props => (props.left ? 'initial' : 'center')};

	@media (min-width: ${props => props.theme.sizes.maxTablet}) {
		flex-direction: row;
		flex-flow: wrap;
	}
`

export const BlockIcon = styled.div`
	display: flex;
	flex-direction: column;
	text-align: center;
	max-width: 250px;
	align-items: center;
`

export const Title = styled.h3`
	font-size: 16px;
	margin-top: 20px;
	margin-bottom: 10px;
	font-family: ${props => props.theme.fonts.medium};
`

export const BlockIconLeft = styled.div`
	display: flex;
	flex-direction: row;
	text-align: left;
`

export const TitleLeft = styled.h3`
	font-size: 20px;
	margin-top: 0;
	margin-bottom: 10px;
	font-family: ${props => props.theme.fonts.medium};
`

export const BlockIconLeftContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-left: 20px;
`

export const Content = styled.p``
